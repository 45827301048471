import { TextAreaStyled, TextFieldStyled } from '@nike/nike-design-system-components';
import { Edit } from '@nike/nike-design-system-icons';
import { isSome, match, Default } from '@nike/rcf-fp';
import Big from 'big.js';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import PickupDeclarationModal from './PickupDeclarationModal';
import { getSchema, getVarianceReason } from './validation';
import { useGetStoreInfo } from '../../../../globals';
import { useGetLocalization } from '../../../../hooks';
import { getFormattedCurrency, fixedDecimalCount } from '../../../../utils/currencyUtils';
import {
  ID_ACTUAL_AMOUNT,
  ID_VARIANCE_EXPLANATION,
  ID_VARIANCE_REASONS,
} from '../../../../utils/dom-element-ids';
import { getMaxAmountByCurrency } from '../../../../utils/getMaxAllowableAmount';
import {
  ErrorMessage,
  MultipleCurrencyInput,
  MultipleCurrencyText,
  Table,
  VarianceReasonModal,
} from '../../../reusable';

import '../../../reusable/VarianceReasonRow.css';

const isActualAmount = ({ actualAmount }) => isSome(actualAmount);
const isCurrentFloat = ({ currentFloat }) => isSome(currentFloat);
const getAmountByKey = (key) => (item) => fixedDecimalCount(new Big(item[key]).toNumber());
const getActualAmount = getAmountByKey('actualAmount');
const getCurrentFloat = getAmountByKey('currentFloat');
const getDefaultFloat = getAmountByKey('defaultFloat');

const getAmount = (item) =>
  match(item)(
    [isActualAmount, getActualAmount],
    [isCurrentFloat, getCurrentFloat],
    [Default, fixedDecimalCount(Number(0))],
  );

function OpenTableItem({ item, callBack }) {
  const getLocalization = useGetLocalization();
  const { currency: storeCurrency, locale } = useGetStoreInfo();
  // prevent default float messaging unless tenderType is CASH
  const isCashTenderType = item.tenderType === 'CASH';
  // this was not translated previously
  const { acceptedLoanAmount } = item;
  const isAcceptedLoan = isCashTenderType && isSome(acceptedLoanAmount) && acceptedLoanAmount > 0;
  const mcuPickupMessage = isCashTenderType
    ? getLocalization('msg.enter-amount-of-cash-for-pickup')
    : getLocalization('msg.enter-tender-amount');
  const [currency, setCurrency] = useState('');
  const currentFloat = item.currentFloat ? getCurrentFloat(item) : 0;
  const [actualAmount, setActualAmount] = useState(getAmount(item));
  const defaultFloat = item.defaultFloat ? getDefaultFloat(item) : 0;
  const expectedPickupAmount = currentFloat - defaultFloat;
  const variance = fixedDecimalCount(Math.abs(actualAmount - expectedPickupAmount));

  const updateAmount = (newValue) => {
    setCurrency(storeCurrency);
    setActualAmount(newValue);
    callBack({
      ...item,
      actualAmount: parseFloat(newValue),
      variance: parseFloat(variance),
    });
  };

  return (
    <div>
      <div className="mb4-sm">
        <MultipleCurrencyInput
          isReadOnly={!isCashTenderType}
          allowNegativeValue={false}
          currency={currency}
          id={ID_ACTUAL_AMOUNT}
          label={getLocalization('lbl.pickup-amount')}
          message={mcuPickupMessage}
          name={ID_ACTUAL_AMOUNT}
          setAmount={updateAmount}
          setCurrency={setCurrency}
          value={parseFloat(actualAmount)}
          onChange={({ value: newValue }) => updateAmount(newValue)}
        />
        {isAcceptedLoan && (
          <div className="text-color-error">
            {getLocalization('msg.template.till-safe-loan-return', {
              AMOUNT: getFormattedCurrency(locale, storeCurrency, acceptedLoanAmount),
            })}
          </div>
        )}
        {isCashTenderType && (
          <div className="mt2-sm pl3-sm ta-sm-l">
            {getLocalization('lbl.default-float')}: <MultipleCurrencyText value={defaultFloat} />
          </div>
        )}
        <div className="mt2-sm pl3-sm ta-sm-l">
          {getLocalization('lbl.variance')}: <MultipleCurrencyText value={variance} />
        </div>
      </div>
    </div>
  );
}

OpenTableItem.defaultProps = {
  callBack: undefined,
};

OpenTableItem.propTypes = {
  callBack: PropTypes.func,
  item: PropTypes.shape({
    acceptedLoanAmount: PropTypes.number,
    actualAmount: PropTypes.number.isRequired,
    currentCount: PropTypes.number.isRequired,
    currentFloat: PropTypes.number.isRequired,
    defaultFloat: PropTypes.number.isRequired,
    tenderType: PropTypes.string.isRequired,
  }).isRequired,
};

// eslint-disable-next-line react/prop-types, react/function-component-definition, prettier/prettier
const getOpenableIconOrNull = (Icon) => ({ rowIsOpenable }) => rowIsOpenable ? <Icon /> : null;

function AmountsTable({
  id,
  isDisabled,
  pickupAmount,
  rowsAreOpenable,
  setVirtualTenders,
  submit,
  submitError,
  submitLoading,
  submitSuccess,
  tillNumber,
  virtualTenders,
}) {
  const getLocalization = useGetLocalization();
  const { currency } = useGetStoreInfo();
  const { varianceExplanation } = virtualTenders[0];

  const validationConfig = {
    fields: {
      pickupAmount: {
        MAX_AMOUNT: getMaxAmountByCurrency(currency),
        NONNEGATIVE: getLocalization('msg.pickup-amount-must-be-zero-or-positive'),
        MIN: getLocalization('msg.is-a-required-field'),
        MAX: getLocalization('msg.number-exceeds-max'),
      },
    },
    variance: {
      short: {
        VALUE: 'Short',
        LABEL: getLocalization('lbl.variance-short'),
      },
      over: {
        VALUE: 'Over',
        LABEL: getLocalization('lbl.variance-over'),
      },
      none: {
        VALUE: 'No Variance',
        LABEL: getLocalization('lbl.no-variance'),
      },
      negativeTillBalance: {
        VALUE: 'Negative Till Balance',
        LABEL: getLocalization('lbl.variance-negative-till-balance'),
      },
    },
  };

  const validate = getSchema(validationConfig);

  const { success, error } = validate.safeParse(virtualTenders[0]);

  const fieldErrors = !success && error.flatten().fieldErrors;

  const errorMessage = !success && fieldErrors?.actualAmount[0];
  const errorDisplay = errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null;

  const updateItem = (updatedItem) => {
    const itemIndex = virtualTenders.findIndex(
      (item) =>
        item.tenderType === updatedItem.tenderType &&
        item.subTenderType === updatedItem.subTenderType,
    );
    setVirtualTenders([
      ...virtualTenders.slice(0, itemIndex),
      updatedItem,
      ...virtualTenders.slice(itemIndex + 1),
    ]);
  };

  const { currentFloat, defaultFloat } = virtualTenders[0];

  return (
    <div id={id}>
      <Table
        Open={OpenTableItem}
        callBack={updateItem}
        columns={[
          {
            header: getLocalization('lbl.tender-type'),
            processor: ({ subTenderType, tenderType }) =>
              subTenderType ? `${tenderType}-${subTenderType}` : tenderType,
            width: 5,
          },
          {
            getClassName: () => 'ta-sm-r', // styling for column header
            header: getLocalization('lbl.amount'),
            headerClassName: 'ta-sm-r', // styling for items in the column
            processor: getAmount,
            width: 3,
          },
          {
            getClassName: () => 'ta-sm-r',
            header: getLocalization('lbl.count'),
            headerClassName: 'ta-sm-r',
            processor: ({ currentCount }) => currentCount,
            width: 3,
          },
          {
            getClassName: () => 'ta-sm-r',
            headerClassName: 'ta-sm-r',
            processor: getOpenableIconOrNull(Edit),
            width: 1,
          },
        ]}
        data={virtualTenders.map((item) => ({
          ...item,
          isOpenable: item.tenderType === 'CASH',
          isDisabled: item.tenderType !== 'CASH',
        }))}
        rowsAreOpenable={rowsAreOpenable}
        title={getLocalization('lbl.amounts')}
      />
      <div className="variance-reason-row pb3-sm">
        <TextFieldStyled
          readOnly
          id={ID_VARIANCE_REASONS}
          label={getLocalization('lbl.variance-reason')}
          name="varianceReason"
          value={() => {
            const newVarianceReason = getVarianceReason(
              fixedDecimalCount(virtualTenders[0].actualAmount),
              fixedDecimalCount(currentFloat),
              fixedDecimalCount(defaultFloat),
              validationConfig.variance,
            );
            virtualTenders[0].varianceReason = newVarianceReason.VALUE;
            return newVarianceReason.LABEL;
          }}
        />
        <div className="pb3-sm">
          <VarianceReasonModal
            className="dialogModal"
            modalTitle={getLocalization('lbl.variance-reason-definitions')}
            additionalReasons={[
              {
                reason: 'lbl.variance-negative-till-balance',
                definition: 'lbl.variance-negative-till-balance-definition',
              },
            ]}
          />
        </div>
      </div>
      {errorDisplay}
      <TextAreaStyled
        className='class="mt2-lg mb4-lg'
        rows={4}
        type="string"
        name="varianceExplanation"
        id={ID_VARIANCE_EXPLANATION}
        label={getLocalization('lbl.variance-explanation')}
        value={varianceExplanation}
        onChange={({ target: { value: newValue } }) => {
          const [first, ...rest] = virtualTenders;
          setVirtualTenders([{ ...first, varianceExplanation: newValue }, ...rest]);
        }}
      />
      <PickupDeclarationModal
        isDisabled={!success || isDisabled || submitLoading}
        modalTitle={getLocalization('lbl.till-final-pickup-declaration')}
        pickupAmount={pickupAmount}
        submit={submit}
        submitError={submitError}
        submitLoading={submitLoading}
        submitSuccess={submitSuccess}
        tillNumber={tillNumber}
        virtualTenders={virtualTenders}
      />
    </div>
  );
}

AmountsTable.propTypes = {
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  pickupAmount: PropTypes.number.isRequired,
  rowsAreOpenable: PropTypes.bool.isRequired,
  setVirtualTenders: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitError: PropTypes.string.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitSuccess: PropTypes.string.isRequired,
  tillNumber: PropTypes.number.isRequired,
  virtualTenders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default AmountsTable;
